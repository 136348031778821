// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-404-js": () => import("./../../../src/pages/de/404.js" /* webpackChunkName: "component---src-pages-de-404-js" */),
  "component---src-pages-de-alle-artikel-js": () => import("./../../../src/pages/de/alle-artikel.js" /* webpackChunkName: "component---src-pages-de-alle-artikel-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-all-articles-js": () => import("./../../../src/pages/en/all-articles.js" /* webpackChunkName: "component---src-pages-en-all-articles-js" */),
  "component---src-pages-es-404-js": () => import("./../../../src/pages/es/404.js" /* webpackChunkName: "component---src-pages-es-404-js" */),
  "component---src-pages-es-todos-los-articulos-js": () => import("./../../../src/pages/es/todos-los-articulos.js" /* webpackChunkName: "component---src-pages-es-todos-los-articulos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-404-js": () => import("./../../../src/pages/it/404.js" /* webpackChunkName: "component---src-pages-it-404-js" */),
  "component---src-pages-it-tutti-gli-articoli-js": () => import("./../../../src/pages/it/tutti-gli-articoli.js" /* webpackChunkName: "component---src-pages-it-tutti-gli-articoli-js" */),
  "component---src-pages-tous-les-articles-js": () => import("./../../../src/pages/tous-les-articles.js" /* webpackChunkName: "component---src-pages-tous-les-articles-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-de-template-js": () => import("./../../../src/templates/DeTemplate.js" /* webpackChunkName: "component---src-templates-de-template-js" */),
  "component---src-templates-en-template-js": () => import("./../../../src/templates/EnTemplate.js" /* webpackChunkName: "component---src-templates-en-template-js" */),
  "component---src-templates-es-template-js": () => import("./../../../src/templates/EsTemplate.js" /* webpackChunkName: "component---src-templates-es-template-js" */),
  "component---src-templates-it-template-js": () => import("./../../../src/templates/ItTemplate.js" /* webpackChunkName: "component---src-templates-it-template-js" */)
}

